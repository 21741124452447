Table {
    border-collapse: collapse;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    

}

.type {
    background-color: #222;
    color: #fefefe;
    /* padding: 8px 15px 8px 15px; */
    text-align: left;
    font-size: 0.85rem;
    width: 120px;

}

Td {
    padding: 5px 5px 5px 5px;
    text-align: left;
    font-size: 0.85rem;
    width: 120px;
}

@media screen and (max-width:480px) {

    Td {
        font-size: 0.8rem;
    }
}