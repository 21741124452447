  form {
    display: flex;
    margin: 0 200px;
    align-items: center;
    justify-content: center;
    flex: wrap;
    flex-direction: column;
    @media screen and (max-width: 480px) {
      margin: 0 0;
      // padding-left: 50px;
    }
    input,
    textarea {
      font: 18px Helvetica, Arial, sans-serif;
      box-sizing: border-box;
      display: block;
      border: none;
      padding: 20px;
      width: 400px;
      margin-bottom: 20px;
      font-size: 18px;
      outline: none;
      transition: all 0.2s ease-in-out;
      resize: none;

      @media screen and (max-width: 480px) {
        width: 350px;
      }
      &::placeholder {
        transition: all 0.2s ease-in-out;
        color: #999;
        font: 18px Helvetica, Arial, sans-serif;
      }
  
      &:focus,
      &.populated {
        padding-top: 28px;
        padding-bottom: 12px;
  
        &::placeholder {
          color: transparent;
        }
      }
    }
  
    textarea {
      height: 200px;
    }
  
    button {
      transition: all 0.2s ease-in-out;
      font-size: 18px;
      border: none;
      background: #0c0c0c;
      color: #fff;
      padding: 20px;
      width: 400px;
      text-transform: uppercase;
      letter-spacing: 3px;

      @media screen and (max-width: 480px) {
        width: 350px;
      }
      &:hover {
        background: #D13E1B;
        cursor: pointer;
      }
    }
  }