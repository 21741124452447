Table {
    border-collapse: collapse;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    

}
@media only screen and (max-width:768px){
    Table{
    margin-bottom: 25px;
    margin-top: 25px;
    /* margin-left: 50px;
    margin-right: 50px; */
    }
}
.type {
    background-color: #222;
    color: #fefefe;
    padding: 8px 5px 8px 5px;
    text-align: left;
}

Td {
    padding: 8px 5px 8px 5px;
    text-align: left;
    font-size: 15px;
    width: 50px;
    
}