.container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  padding-right: -30px;
  margin-left: -30px;

h1 {
text-align: center;
color: #0c0c0c;
font-size: 18px;
font-weight: 400;
padding-top: 5px;
padding-bottom: 5px;
}
@media screen and (max-width:768px){
  h1{
    font-size: 22px;
    font-weight: 100;
  }
}
.progressbar-container {
position: relative;
width: 250px;
height: 25px;
border-radius: 20px;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
background-color: #eee;
color: #fff;

.progressbar-complete {
position: absolute;
left: 0;
top: 0px;
height: 100%;
border-radius: 10px;
animation: g 2500ms infinite ease-in-out;

.progressbar-liquid {
width: 50px;
height: 50px;
animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
position: absolute;
right: -5px;
top: -10px;
border-radius: 40%;
}
}
.progress1 {
color: #0c0c0c;
// font-weight: bold;
}
.progress2 {
  z-index: 2;
  color: #fefefe;
  // font-weight: bold;
  }
}
}

@keyframes g {
0% { background-color: #D13E1B; }
50% { background-color: #d11b76; }
100% { background-color: #D13E1B; }
}

@keyframes r {
from { transform: rotate(0deg); }
from { transform: rotate(360deg); }
}


@media screen and (max-width:768px){
.container{
  margin-top: 10px;
  margin-right: 0;
  padding-right: 0;
  margin-left: 0;
  margin-bottom: 10px;  
}
.progressbar-container{
  width: 300px;
}
}