blockquote {
  /* margin: 1.5em 0 1.5em; */
  padding: 1rem 2em 0 2.5em;
  position:relative;
  background: #f9f9f9;
  border-radius: 2rem;
  font-size: 1.3em;
}
blockquote:before
 {
  color: #D13E1B;
  content: "\201C";
  font-size: 5em;
  position:absolute;
  left:5px;
  top: 0.3em;
  line-height: 0.1em;
}

blockquote:after
 {
   color: #D13E1B;
  content: "\201D";
  font-size: 5em;
  position:absolute;
  right:3px;
  bottom: 0em;
  line-height: 0.1em;
}
blockquote footer {
  margin-top: .5em;
  padding: 0;
  color: #D13E1B;
  font-size: 12pt;
  text-align: left;
  font-style: italic;
}
blockquote footer:before {
  content: '— ';
}