.head {
    background-color: #222;
    color: #fefefe;
    font-weight: bold;
}

Table {
    border-collapse: collapse;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);

}
/* @media only screen and (max-width:768px){
    Table{
    margin-bottom: 40px;
    margin-left: 50px;
    margin-right: 50px;
    }
} */
.row:hover {
    background-color: #eee;
    cursor: pointer;
}

.row{
    background-color: #fefefe;
}